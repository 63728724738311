import React from 'react';

// Gets the HTML for the metadata page
export const getPatchingDashboardHTML = (
  hidePatchingDashboard,
  loadedFileData,
  handleFileUpload,
  shouldDisableUpload = loadedFileData.foundAmis.length +
    loadedFileData.unknownAmis.length ==
    0,
) => {
  return (
    <div className="content" hidden={hidePatchingDashboard}>
      <div className="patching-bar">
        <label className="patching-title">
          Patching Missing and RED Instance Data
        </label>
        <div className="file-upload">
          <label className="patching-loading" hidden={!loadedFileData.loading}>
            loading file, this may take a moment...
          </label>
          <input
            type="file"
            id="patchingCSV"
            name="filename"
            accept=".csv"
            onChange={(e) => handleFileUpload(e.target.files[0])}
          />
          <button disabled={shouldDisableUpload}>Upload</button>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="patching-body">
        {/* {JSON.stringify(loadedFileData.foundAmis)}
        {JSON.stringify(loadedFileData.unknownAmis)} */}
        Found AMIs
        <table className="package-table">
          <tbody>
            <tr className="metadata-row">
              <td>Name</td>
              <td>AMI ID</td>
              <td>Region</td>
              <td>Stage</td>
              <td>State</td>
              <td>Count</td>
            </tr>
            {loadedFileData.foundAmis.map((item, i) => {
              //  Returns the row HTML for each row in the list
              return (
                <tr key={i} className="metadata-row">
                  <td>{item['Name']}</td>
                  <td>{item['AmiId']}</td>
                  <td>{item['Region']}</td>
                  <td>{item['Stage']}</td>
                  <td>{item['State']}</td>
                  <td>{item['Count']}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
        Unknown AMIs
        <table className="package-table">
          <tbody>
            <tr className="metadata-row">
              <td>AMI ID</td>
              <td>Count</td>
            </tr>
            {loadedFileData.unknownAmis.map((item, i) => {
              //  Returns the row HTML for each row in the list
              return (
                <tr key={i} className="metadata-row">
                  <td>{item['AmiId']}</td>
                  <td>{item['Count']}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

////////////////////////////////////////////////////////////////////////////
// HELPER FUNCTIONS
////////////////////////////////////////////////////////////////////////////

export default getPatchingDashboardHTML;
