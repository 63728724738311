import React from 'react';

export const getReleaseHTML = (
  syncTime,
  tablePVs,
  regionData,
  expandedVersions,
  expandAllVersionsState,
  hideTable,
  hidePatchingDashboard,
  onChangeValuePV,
  onChangeValueRegion,
  invertSort,
  invertRegions,
  runSearch,
  getStatusClass,
  parseContainerVersion,
  parseCapabilities,
  handleCapabilitiesClick,
  getCapabilitiesDisplay,
  getExpandAllCapabilitiesDisplay,
  handleVersionClick,
  getVersionDisplay,
  getExpandAllVersionsDisplay,
  clickExpandAllCapabilities,
  clickExpandAllVersions,
  clickAmiId,
  metadata,
  forceRefresh,
) => {
  return (
    <div className="content" hidden={hideTable.info || !hidePatchingDashboard}>
      {/******************* Table Buttons **************************************/}
      <div className="sort-buttons">
        <label className="radio-text">Sort By:</label>
        <input
          className="btn-sort"
          type="radio"
          value="default"
          name="sortPVs"
          defaultChecked
          onChange={onChangeValuePV}
        />
        <label className="radio-text">Default</label>
        <input
          className="btn-sort"
          type="radio"
          value="name"
          name="sortPVs"
          onChange={onChangeValuePV}
        />
        <label className="radio-text">Name</label>
        <input
          className="btn-sort"
          type="radio"
          value="family"
          name="sortPVs"
          onChange={onChangeValuePV}
        />
        <label className="radio-text">Family</label>
        <input
          className="btn-sort"
          type="radio"
          value="version"
          name="sortPVs"
          onChange={onChangeValuePV}
        />
        <label className="radio-text">Version</label>
        <input
          className="btn-sort"
          type="radio"
          value="agent"
          name="sortPVs"
          onChange={onChangeValuePV}
        />
        <label className="radio-text">Agent Version</label>
        <input
          className="btn-sort"
          type="radio"
          value="container"
          name="sortPVs"
          onChange={onChangeValuePV}
        />
        <label className="radio-text">Container Version</label>
        <button className="btn-invert" onClick={invertSort}>
          Invert
        </button>
        <input
          type="text"
          className="searchbar"
          placeholder="Search"
          onChange={runSearch}
        ></input>
        {getRegionButtons(
          expandAllVersionsState,
          onChangeValueRegion,
          invertRegions,
        )}
        <div className="sync-text">
          <label className="sync-label">
            {
              JSON.stringify(syncTime.info)
                .replace(/\"/g, '')
                .split(';')[0]
            }
          </label>
          <label className="sync-label">
            {
              JSON.stringify(syncTime.info)
                .replace(/\"/g, '')
                .split(';')[1]
            }
          </label>
        </div>
        <button className="btn-refresh" onClick={forceRefresh}>
          <img
            className="refresh-logo"
            src={require('../images/refresh.png')}
            alt={'Force Refresh Data'}
          />
        </button>
      </div>
      <table className="pv-table">
        <tbody>
          {/******************* Table Header **************************************/}
          <tr className="pv-table-row-header">
            <th className="pv-table-column-header pv-name">Name</th>
            <th className="pv-table-column-header pv-family">Family</th>
            <th className="pv-table-column-header pv-version">Version</th>
            <th className="pv-table-column-header pv-revision">Revision</th>
            <th className="pv-table-column-header pv-provider">Provider</th>
            <th className="pv-table-column-header pv-agent">Agent Version</th>
            <th className="pv-table-column-header pv-container">
              Container Engine Version
            </th>
            <th
              className="pv-table-column triangle"
              onClick={clickExpandAllVersions}
            >
              {getExpandAllVersionsDisplay()}
            </th>
            <th className="pv-table-column-header pv-region-name">
              Region Name
            </th>
            <th className="pv-table-column-header pv-region-code">
              Region Code
            </th>
            <th className="pv-table-column-header pv-internal">Zone Code</th>
            <th className="pv-table-column-header pv-traffic-weight">
              Traffic Weight
            </th>
            <th className="pv-table-column-header pv-ami-id">Tagged AMI ID</th>
            <th className="pv-table-column triangle"></th>
            <th className="pv-table-column-header pv-capabilities">
              Capabilities
            </th>
            <th className="pv-table-column-header pv-stage">Stage</th>
            <th className="pv-table-column-header pv-state">State</th>
          </tr>
        </tbody>
      </table>
      <table className="pv-table pv-table-body">
        <tbody>
          {tablePVs.info.map((item) =>
            item.map((entry, i) => {
              const pvKey =
                entry['Name'] + entry['InternalZone'] + entry['Stage'];
              const clickCapabilities = () => handleCapabilitiesClick(pvKey);
              const clickVersion = () => handleVersionClick(entry['Name']);
              const clickAmi = () => clickAmiId(true, entry['Name']);
              if (i == 0) {
                // If row is the first row for the current PV, display all attributes
                /////////////////// FIRST ROW ////////////////////////////////////
                return (
                  <tr
                    className="pv-table-row"
                    key={
                      entry['Name'] +
                      '-' +
                      entry['InternalZone'] +
                      '-' +
                      entry['Stage']
                    }
                  >
                    <td className="pv-table-column pv-name">
                      {JSON.stringify(entry['Name']).replace(/\"/g, '')}
                    </td>
                    <td className="pv-table-column pv-family">
                      {JSON.stringify(entry['Family']).replace(/\"/g, '')}
                    </td>
                    <td className="pv-table-column pv-version">
                      {JSON.stringify(entry['Version']).replace(/\"/g, '')}
                    </td>
                    <td className="pv-table-column pv-revision">
                      {JSON.stringify(entry['Revision']).replace(/\"/g, '')}
                    </td>
                    <td className="pv-table-column pv-provider">
                      {JSON.stringify(entry['Provider']).replace(/\"/g, '')}
                    </td>
                    <td className="pv-table-column pv-agent">
                      {JSON.stringify(entry['ECSAgentVersion']).replace(
                        /\"/g,
                        '',
                      )}
                    </td>
                    <td className="pv-table-column pv-container">
                      {parseContainerVersion(
                        JSON.stringify(entry['ContainerEngineVersion']).replace(
                          /\"/g,
                          '',
                        ),
                      )}
                    </td>
                    {getRegionArrow(entry, clickVersion, getVersionDisplay)}
                    {getRegionName(regionData, entry)}
                    {getRegionCode(regionData, entry)}
                    {getZoneCode(entry)}
                    <td className="pv-table-column pv-traffic-weight">
                      {JSON.stringify(entry['TrafficWeight']).replace(
                        /\"/g,
                        '',
                      )}
                    </td>
                    {getAMIid(entry, clickAmi, metadata)}
                    {getCapabilitiesArrow(
                      clickCapabilities,
                      getCapabilitiesDisplay,
                      pvKey,
                      parseCapabilities,
                      entry,
                    )}
                    {getCapabilities(
                      getCapabilitiesDisplay,
                      pvKey,
                      parseCapabilities,
                      entry,
                    )}
                    {getStage(entry)}
                    {getState(entry, getStatusClass)}
                  </tr>
                );
              } else if (expandedVersions.info.includes(entry['Name'])) {
                // If row is a repeated pv, only display potentially different attributes
                /////////////////// OTHER ROWS ////////////////////////////////////
                return (
                  <tr
                    key={entry['Name'] + entry['InternalZone'] + entry['Stage']}
                  >
                    <td className="pv-table-column"></td>
                    <td className="pv-table-column"></td>
                    <td className="pv-table-column"></td>
                    <td className="pv-table-column"></td>
                    <td className="pv-table-column"></td>
                    <td className="pv-table-column"></td>
                    <td className="pv-table-column"></td>
                    <td className="pv-table-column"></td>
                    {getRegionName(regionData, entry)}
                    {getRegionCode(regionData, entry)}
                    {getZoneCode(entry)}
                    <td className="pv-table-column pv-traffic-weight">
                      {JSON.stringify(entry['TrafficWeight']).replace(
                        /\"/g,
                        '',
                      )}
                    </td>
                    {getAMIid(entry, clickAmi, metadata)}
                    {getCapabilitiesArrow(
                      clickCapabilities,
                      getCapabilitiesDisplay,
                      pvKey,
                      parseCapabilities,
                      entry,
                    )}
                    {getCapabilities(
                      getCapabilitiesDisplay,
                      pvKey,
                      parseCapabilities,
                      entry,
                    )}
                    {getStage(entry)}
                    {getState(entry, getStatusClass)}
                  </tr>
                );
              }
            }),
          )}
        </tbody>
      </table>
      <br />
      <br />
      <br />
    </div>
  );
};

////////////////////////////////////////////////////////////////////////////
// REUSABLE FUNCTIONS
////////////////////////////////////////////////////////////////////////////
// TABLE //////////////////////////////////////////////////////////////////
export const getRegionArrow = (entry, clickVersion, getVersionDisplay) => {
  return (
    <td
      className="pv-table-column triangle pv-region-arrow"
      onClick={clickVersion}
    >
      {getVersionDisplay(entry['Name'])}
    </td>
  );
};

export const getRegionName = (regionData, entry) => {
  if (entry['InternalZone'] == undefined) {
    return <td className="pv-table-column pv-region-name"></td>;
  }
  return (
    <td className="pv-table-column pv-region-name">
      {regionData.info[entry['InternalZone']] == undefined
        ? ''
        : regionData.info[entry['InternalZone']][1]}
    </td>
  );
};

export const getRegionCode = (regionData, entry) => {
  if (entry['InternalZone'] == undefined) {
    return <td className="pv-table-column pv-region-code"></td>;
  }
  return (
    <td className="pv-table-column pv-region-code">
      {regionData.info[entry['InternalZone']] == undefined
        ? ''
        : regionData.info[entry['InternalZone']][0]}
    </td>
  );
};

export const getZoneCode = (entry) => {
  if (entry['InternalZone'] == undefined) {
    return <td className="pv-table-column pv-internal"></td>;
  }
  return (
    <td className="pv-table-column pv-internal">
      {JSON.stringify(entry['InternalZone']).replace(/\"/g, '')}
    </td>
  );
};

export const getAMIid = (entry, clickAmi, metadata) => {
  if (entry['EC2AmiId'] == undefined) {
    return <td className="pv-table-column pv-ami-id"></td>;
  } else if (
    entry['Name'] + '.txt' in metadata.ami_info &&
    entry['Stage'] == 'Prod'
  ) {
    return (
      <td className="pv-table-column pv-ami-id">
        <button className="pv-ami-button" onClick={clickAmi}>
          {JSON.stringify(entry['EC2AmiId']).replace(/\"/g, '')}
        </button>
      </td>
    );
  }
  return (
    <td className="pv-table-column pv-ami-id">
      {JSON.stringify(entry['EC2AmiId']).replace(/\"/g, '')}
    </td>
  );
};

export const getCapabilitiesArrow = (
  clickCapabilities,
  getCapabilitiesDisplay,
  pvKey,
  parseCapabilities,
  entry,
) => {
  if (
    getCapabilitiesDisplay(
      pvKey,
      parseCapabilities(JSON.stringify(entry['Capabilities'])),
    ) == undefined
  ) {
    return <td className="pv-table-column triangle"></td>;
  }
  return (
    <td className="pv-table-column triangle" onClick={clickCapabilities}>
      {
        getCapabilitiesDisplay(
          pvKey,
          parseCapabilities(JSON.stringify(entry['Capabilities'])),
        )[0]
      }
    </td>
  );
};

export const getCapabilities = (
  getCapabilitiesDisplay,
  pvKey,
  parseCapabilities,
  entry,
) => {
  if (
    getCapabilitiesDisplay(
      pvKey,
      parseCapabilities(JSON.stringify(entry['Capabilities'])),
    ) == undefined
  ) {
    return <td className="pv-table-column pv-capabilities"></td>;
  }
  return (
    <td className="pv-table-column pv-capabilities">
      {getCapabilitiesDisplay(
        pvKey,
        parseCapabilities(JSON.stringify(entry['Capabilities'])),
      )[1].map((cap) => {
        return <div key={cap}>{cap}</div>;
      })}
    </td>
  );
};

export const getStage = (entry) => {
  if (entry['Stage'] == undefined) {
    return <td className="pv-table-column pv-stage"></td>;
  }
  return (
    <td className="pv-table-column pv-stage">
      {JSON.stringify(entry['Stage']).replace(/\"/g, '')}
    </td>
  );
};

export const getState = (entry, getStatusClass) => {
  if (entry['State'] == undefined) {
    return <td className="UNKNOWN_PLATFORM"></td>;
  }
  return (
    <td
      className={getStatusClass(
        JSON.stringify(entry['State']).replace(/\"/g, ''),
      )}
    >
      {JSON.stringify(entry['State']).replace(/\"/g, '')}
    </td>
  );
};

export const getRegionButtons = (
  expandAllVersionsState,
  onChangeValueRegion,
  invertRegions,
) => {
  if (expandAllVersionsState.info == 'expanded') {
    return (
      <div className="inline-div">
        &nbsp;&nbsp;&nbsp;&nbsp;
        <label className="radio-text">Arrange By:</label>
        <input
          className="btn-sort"
          type="radio"
          value="zone"
          name="sortReg"
          defaultChecked
          onChange={onChangeValueRegion}
        />
        <label className="radio-text">Zone</label>
        <input
          className="btn-sort"
          type="radio"
          value="state"
          name="sortReg"
          onChange={onChangeValueRegion}
        />
        <label className="radio-text">State</label>
        <button className="btn-invert" onClick={invertRegions}>
          Invert Regions
        </button>
      </div>
    );
  }
};

export default getReleaseHTML;
