import React from 'react';

// Gets the HTML for the metadata page
export const getMetadataHTML = (hideTable, clickAmiId, metadata) => {
  // When the Back To Table button is clicked, set the current AMI to an invalid id (7)
  // This tells the main display to hide the AMI metadata page
  const clickAmi = () => clickAmiId(false, '');
  return (
    <div className="content" hidden={!hideTable.info}>
      <div className="metadata-bar">
        <button className="metadata-button" onClick={clickAmi}>
          Back to Table
        </button>
        <label className="metadata-title">
          {hideTable.ami_name + ' Metadata'}
        </label>
      </div>
      <br />
      <br />
      <div className="metadata-body">
        {selectMetadataTable(metadata, hideTable.ami_name)}
      </div>
    </div>
  );
};

////////////////////////////////////////////////////////////////////////////
// HELPER FUNCTIONS
////////////////////////////////////////////////////////////////////////////
// Returns the appropriate metadata table or an empty string if an AMI name is not present
function selectMetadataTable(metadata, ami_name) {
  if (ami_name != '') {
    return getMetadataTable(metadata.ami_info[ami_name + '.txt']);
  }
  return '';
}

// Gathers and displays the metadata tables
function getMetadataTable(metadata) {
  if (metadata != '') {
    metadata = metadata.split('PACKAGE DATA');
    return (
      <div>
        <div>{getContainerTable(String(metadata[0]))}</div>
        <div>{getPackageTable(String(metadata[1]))}</div>
        <br />
        <br />
        <br />
      </div>
    );
  }
  return <div>Metadata Not Found</div>;
}

// Displays the Container.txt file as a table
function getContainerTable(metadata) {
  if (metadata.includes('container data is currently unavailable.')) {
    return <div>{metadata}</div>;
  }
  // Clean up the metadata string and turn it into a list of rows
  let container_data = metadata
    .replace('CONTAINER DATA\n', '')
    .replaceAll('\n', '█')
    .replaceAll(' MiB', 'MiB')
    .replaceAll(' KiB', 'KiB')
    .replace(/\s\s+/g, ',')
    .replaceAll(' ', ',')
    .split('█');
  return (
    <div>
      <div className="metadata-header">CONTAINER DATA</div>
      <table className="container-table">
        <tbody>
          {container_data.slice(0, -3).map((item, i) => {
            var row = item.split(',');
            // Returns the row HTML for each row in the list
            return (
              <tr key={i} className="metadata-row">
                <td className="container-ref metadata-column">{row[0]}</td>
                <td className="container-type">{row[1]}</td>
                <td className="container-digest">{row[2]}</td>
                <td className="container-size">{row[3]}</td>
                <td className="container-platforms">{row[4]}</td>
                <td className="container-labels">{row[5]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

// Displays the Package.txt file as a table
function getPackageTable(metadata) {
  if (metadata.includes('package data is currently unavailable.')) {
    return <div>{'PACKAGE DATA' + metadata}</div>;
  }
  // Clean up the metadata string and turn it into a list of rows. Slice off the headers
  let package_data = metadata
    .split('AMI_IDS')[0]
    .replaceAll('\n', '█')
    .replace(/\s\s+/g, ',')
    .replaceAll(' ', ',')
    .split('█');
  let package_table = package_data.slice(3, -1);
  return (
    <div>
      <div className="metadata-header">PACKAGE DATA</div>
      <div>{package_data[1].replaceAll(',', ' ').replace(/\s\s+/g, ', ')}</div>
      <table className="package-table">
        <tbody>
          <tr className="metadata-row">
            <td className="metadata-column">Installed Packages</td>
          </tr>
          {package_table.map((item, i) => {
            var row = item.split(',');
            //  Returns the row HTML for each row in the list
            return (
              <tr key={i} className="metadata-row">
                <td className="package-name metadata-column">{row[0]}</td>
                <td>{row[1]}</td>
                <td className="package-installed">{row[2]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default getMetadataHTML;
