import React from 'react';
import getReleaseHTML from './releasehtml.js';
import getMetadataHTML from './metadatahtml.js';
import getPatchingDashboardHTML from './patchingdashboardhtml.js';

export const getAppHTML = (
  userInfo,
  syncTime,
  tablePVs,
  regionData,
  expandedVersions,
  expandAllVersionsState,
  hideTable,
  hidePatchingDashboard,
  loadedFileData,
  onChangeValuePV,
  onChangeValueRegion,
  invertSort,
  invertRegions,
  runSearch,
  getStatusClass,
  parseContainerVersion,
  parseCapabilities,
  handleCapabilitiesClick,
  getCapabilitiesDisplay,
  handleExpandAllCapabilitiesClick,
  getExpandAllCapabilitiesDisplay,
  handleVersionClick,
  handleExpandAllVersionsClick,
  getVersionDisplay,
  getExpandAllVersionsDisplay,
  clickAmiId,
  metadata,
  forceRefresh,
  handleClickPatchingDashboard,
  handleFileUpload,
) => {
  const clickExpandAllCapabilities = () => handleExpandAllCapabilitiesClick();
  const clickExpandAllVersions = () => handleExpandAllVersionsClick();
  const clickPatchingDashboard = () =>
    handleClickPatchingDashboard(hidePatchingDashboard);
  ////////////////////////////////////////////////////////////////////////////
  // HTML
  ////////////////////////////////////////////////////////////////////////////
  return (
    <div id="app">
      {/******************* Navbar **************************************/}
      <div className="navbar-pvri">
        <img
          className="logo"
          src={require('../images/fargate.png')}
          alt={'AWS Fargate Logo'}
        />
        <label className="navbar-label navbar-title">
          AWS Fargate Platform Version Release Interface
        </label>
        <button
          className="navbar-patching-button"
          onClick={clickPatchingDashboard}
        >
          {hidePatchingDashboard ? 'View Patching Data' : 'Return to Home'}
        </button>
        <label className="navbar-label navbar-sign-in">
          Signed in as {JSON.stringify(userInfo['info']).replace(/\"/g, '')}
        </label>
      </div>
      {/******************* Table **************************************/}
      <div className="main-view">
        <br />
        <br />
        {getReleaseHTML(
          syncTime,
          tablePVs,
          regionData,
          expandedVersions,
          expandAllVersionsState,
          hideTable,
          hidePatchingDashboard,
          onChangeValuePV,
          onChangeValueRegion,
          invertSort,
          invertRegions,
          runSearch,
          getStatusClass,
          parseContainerVersion,
          parseCapabilities,
          handleCapabilitiesClick,
          getCapabilitiesDisplay,
          getExpandAllCapabilitiesDisplay,
          handleVersionClick,
          getVersionDisplay,
          getExpandAllVersionsDisplay,
          clickExpandAllCapabilities,
          clickExpandAllVersions,
          clickAmiId,
          metadata,
          forceRefresh,
        )}
        {getMetadataHTML(hideTable, clickAmiId, metadata)}
        {getPatchingDashboardHTML(
          hidePatchingDashboard,
          loadedFileData,
          handleFileUpload,
        )}
      </div>
    </div>
  );
};

export default getAppHTML;
