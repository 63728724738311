import React, { useEffect, useState } from 'react';
import getStatusClass from './dynamicClasses.js';
import getAppHTML from './apphtml.js';
import queryString from 'query-string';

function App() {
  ////////////////////////////////////////////////////////////////////////////
  // Functions/JS
  ////////////////////////////////////////////////////////////////////////////
  // States/Variables ////////////////////////////////////////////////////////
  const [pvInfo, setPvInfo] = useState({ loaded: false, info: [] });
  const [historicPvInfo, setHistoricPvInfo] = useState({ info: [] });
  const [userInfo, setUserInfo] = useState({ loaded: false, info: '' });
  const [syncTime, setSyncTime] = useState({ info: '' });
  const [regionData, setRegionData] = useState({ info: {} });
  const [sortBy, setSortBy] = useState({ info: 'name' });
  const [sortRegionBy, setSortRegionBy] = useState({ info: 'zone' });
  const [tablePVs, setTablePVs] = useState({ info: [] });
  const [searchPVs, setSearchPVs] = useState({ info: [] });
  const [expandedCapabilities, setExpandedCapabilities] = useState({
    info: [],
  });
  const [expandAllCapabilitiesState, setExpandAllCapabilitiesState] = useState({
    info: 'collapsed',
  });
  const [expandedVersions, setExpandedVersions] = useState({ info: [] });
  const [expandAllVersionsState, setExpandAllVersionsState] = useState({
    info: 'collapsed',
  });
  const [hideTable, setHideTable] = useState({ info: false, ami_name: '' });
  const [metadata, setMetadata] = useState({ ami_info: {} });
  const [hidePatchingDashboard, setHidePatchingDashboard] = useState(true);
  const [loadedFileData, setLoadedFileData] = useState({
    foundAmis: [],
    unknownAmis: [],
    loading: false,
  });
  const stateEnum = {
    PRE_RELEASE: 0,
    ACTIVE: 1,
    DEPRECATING: 2,
    DEPRECATED: 3,
    RECALLED: 4,
    RETIRED: 5,
    UNKNOWN_PLATFORM: 6,
  };
  const stageEnum = {
    Prod: 0,
    Zeta: 1,
    Gamma: 2,
  };
  const reader = new FileReader();
  useEffect(() => {
    // Authenticate the user
    // Check to see if the user has already been authenticated
    try {
      const parsedQueryString = queryString.parse(window.location.search);
      const { id_token } = parsedQueryString;
      let token = {};
      token.raw = id_token;
      token.payload = JSON.parse(window.atob(id_token.split('.')[1]));
      // If not midway authenticated, redirect to midway
      if (token.payload.iss != 'https://midway-auth.amazon.com') {
        redirect();
      } else {
        setUserInfo({ loaded: true, info: token.payload.sub });
        window.history.replaceState({}, document.title, '/'); // Clear auth token from the url
        const midway_payload = {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: token.payload.iss,
        };

        // Load data after authenticating the user with midway
        // fetch pvInfo
        fetch(window.location.origin + '/pvInfo', midway_payload)
          .then((res) => res.json())
          .then((info) => {
            var jsonList = info['VersionList'];
            var pvList = [];
            for (var pv of jsonList) {
              pvList.push(pv);
            }
            pvList = sortByDefault(pvList);
            updateTableList(pvList);
            setPvInfo({ loaded: true, info: pvList });
            setSearchPVs({ info: pvList });
            setSyncTime({ info: info['SyncTime'] });
          });

        // fetch historicPvInfo
        fetch(window.location.origin + '/historicPvInfo', midway_payload)
          .then((res) => res.json())
          .then((info) => {
            setHistoricPvInfo({ loaded: true, info: info['VersionList'] });
          });

        // fetch metadata
        fetch(window.location.origin + '/metadata', midway_payload)
          .then((res) => res.json())
          .then((info) => {
            setMetadata({
              ami_info: info,
            });
          });
      }
    } catch {
      // Redirect to midway if there is an error
      redirect();
    }

    // Redirects the user to midway
    function redirect() {
      const REDIRECT_URI = window.location.href;
      const CLIENT_ID = window.location.host;
      const AUTH_URL = `https://midway-auth.amazon.com/SSO/redirect?redirect_uri=${encodeURI(
        REDIRECT_URI,
      )}&client_id=https://${encodeURI(
        CLIENT_ID,
      )}&scope=openid&response_type=id_token&nonce=123&sentry_handler_version=MidwayNginxModule-1.3-1`;
      window.location.replace(AUTH_URL);
    }
  }, [setUserInfo, setPvInfo, setMetadata]);

  // Parse data that was loaded
  // Add items to the table list based on name
  function addToTableList(pv, tableList) {
    var found = false;
    for (var list of tableList) {
      if (list[0]['Name'] == pv['Name']) {
        list.push(pv);
        found = true;
      }
    }
    if (found == false) {
      tableList.push([pv]);
    }
    return tableList;
  }
  // Update table list from given list
  function updateTableList(list) {
    var tableList = [];
    for (var pv of list) {
      tableList = addToTableList(pv, tableList);
    }
    tableList =
      sortRegionBy.info == 'zone'
        ? sortByZone(tableList)
        : sortByState(tableList);
    setTablePVs({ info: tableList });
  }
  // Load region data
  useEffect(() => {
    fetch(window.location.origin + '/regionData')
      .then((res) => res.json())
      .then((info) => {
        setRegionData({ info: info });
      });
  }, [setRegionData]);
  // Check midway credentials
  if (JSON.stringify(userInfo['info']) == '"user_nobody"') {
    return (
      <div id="app" className="midway-login">
        Authentication Error: No Midway credentials found. Please run mwinit and
        reload the page.
      </div>
    );
  }
  // Functions ////////////////////////////////////////////////////////
  // Toggle sort value for PVs
  function onChangeValuePV(event) {
    setSortBy({ info: event.target.value });
    sortPvInfo(event.target.value);
    return sortBy;
  }
  // Toggle sort value for Regions
  function onChangeValueRegion(event) {
    setSortRegionBy({ info: event.target.value });
    sortRegionInfo(event.target.value);
    return sortRegionBy;
  }
  // Sort by Default (Newest warmpool 1.4, 1.3, ARM, FC)
  // Latest of each version should be at the top of the list
  // Type values are 0-8:
  // WARMPOOL_1_4_LATEST = 0 WARMPOOL_1_3_LATEST = 1 ARM_LATEST = 2 FIRECRACKER_LATEST = 3
  // WARMPOOL_1_4 = 4 WARMPOOL_1_3 = 5 ARM = 6 FIRECRACKER = 7 OTHER = 8
  // If it is a tie (they are the same type), compare revision numbers
  function sortByDefault(versionList) {
    versionList.sort(function(a, b) {
      var typeA = Number(a.Type);
      var typeB = Number(b.Type);
      return typeA < typeB
        ? -1
        : typeA > typeB
        ? 1
        : Number(a.Revision) < Number(b.Revision)
        ? 1
        : Number(a.Revision) > Number(b.Revision)
        ? -1
        : 0;
    });
    return versionList;
  }
  // Sort by Name
  function sortByName(versionList) {
    versionList.sort(function(a, b) {
      var textA = a.Name.toUpperCase();
      var textB = b.Name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return versionList;
  }
  // Sort by Family
  function sortByFamily(versionList) {
    versionList.sort(function(a, b) {
      var textA = a.Family.toUpperCase();
      var textB = b.Family.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return versionList;
  }
  // Sort by Version
  // versions are saved in different formats, with x.y.z being the most common.
  // occasionally, versions contain non-numeric characters (i.e. 0.0a.bcd)
  // Split the version on '.', and cast the variables to numbers for sorting
  // If the version contained non-numeric charaters, the values will be NaN
  // sort those versions alphabetically instead of numerically
  // Otherwise, sort versions numerically and break ties with revision numbers
  function sortByVersion(versionList) {
    versionList.sort(function(a, b) {
      var aValues = a.Version.split('.').map((x) => Number(x));
      var bValues = b.Version.split('.').map((x) => Number(x));
      if (
        isNaN(aValues[0]) ||
        isNaN(aValues[1]) ||
        isNaN(aValues[2]) ||
        isNaN(bValues[0]) ||
        isNaN(bValues[1]) ||
        isNaN(bValues[2])
      ) {
        return a.Version < b.Version ? -1 : a.Version > b.Version ? 1 : 0;
      }
      return aValues[0] < bValues[0]
        ? -1
        : aValues[0] > bValues[0]
        ? 1
        : aValues[1] < bValues[1]
        ? -1
        : aValues[1] > bValues[1]
        ? 1
        : aValues[2] < bValues[2]
        ? -1
        : aValues[2] > bValues[2]
        ? 1
        : a.Revision.toUpperCase < b.Revision.toUpperCase
        ? 1
        : a.Revision.toUpperCase > b.Revision.toUpperCase
        ? -1
        : 0;
    });
    return versionList;
  }
  // Sort by Agent
  // agent versions are saved in different formats, with x.y.z being the most common.
  // occasionally, agent versions non-numeric characters (i.e. 0.0a.bcd)
  // Split the agent version on '.', and cast the variables to numbers for sorting
  // If the agent version contained non-numeric charaters, the values will be NaN
  // sort those agent versions alphabetically instead of numerically
  // Otherwise, sort agent versions numerically and break ties with revision numbers
  function sortByAgent(versionList) {
    versionList.sort(function(a, b) {
      var aValues = a.ECSAgentVersion.split('.').map((x) => Number(x));
      var bValues = b.ECSAgentVersion.split('.').map((x) => Number(x));
      if (isNaN(aValues[0]) || isNaN(bValues[0])) {
        return a.ECSAgentVersion < b.ECSAgentVersion
          ? -1
          : a.ECSAgentVersion > b.ECSAgentVersion
          ? 1
          : 0;
      }
      if (isNaN(aValues[2]) || isNaN(bValues[2])) {
        return aValues[0] < bValues[0]
          ? -1
          : aValues[0] > bValues[0]
          ? 1
          : aValues[1] < bValues[1]
          ? -1
          : aValues[1] > bValues[1]
          ? 1
          : a.ECSAgentVersion < b.ECSAgentVersion
          ? -1
          : a.ECSAgentVersion > b.ECSAgentVersion
          ? 1
          : 0;
      }
      return aValues[0] < bValues[0]
        ? -1
        : aValues[0] > bValues[0]
        ? 1
        : aValues[1] < bValues[1]
        ? -1
        : aValues[1] > bValues[1]
        ? 1
        : aValues[2] < bValues[2]
        ? -1
        : aValues[2] > bValues[2]
        ? 1
        : 0;
    });
    return versionList;
  }
  // Sort by Container
  function sortByContainer(versionList) {
    versionList.sort(function(a, b) {
      var textA = a.ContainerEngineVersion.toUpperCase();
      var textB = b.ContainerEngineVersion.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return versionList;
  }
  // Sort PV Info based on sort value and update tableList
  function sortPvInfo(sortByValue) {
    switch (sortByValue) {
      case 'default':
        searchPVs.info = sortByDefault(searchPVs.info);
        break;
      case 'name':
        searchPVs.info = sortByName(searchPVs.info);
        break;
      case 'family':
        searchPVs.info = sortByFamily(searchPVs.info);
        break;
      case 'version':
        searchPVs.info = sortByVersion(searchPVs.info);
        break;
      case 'agent':
        searchPVs.info = sortByAgent(searchPVs.info);
        break;
      case 'container':
        searchPVs.info = sortByContainer(searchPVs.info);
        break;
    }
    updateTableList(searchPVs.info);
    return searchPVs.info;
  }
  // Sort regions by zone
  function sortByZone(tablePVList) {
    var tableList = [];
    for (var versionList of tablePVList) {
      versionList.sort(function(a, b) {
        var textA = a.InternalZone.substring(0, 3).toUpperCase();
        var textB = b.InternalZone.substring(0, 3).toUpperCase();
        var numA = parseInt(a.InternalZone.substring(3), 10);
        var numB = parseInt(b.InternalZone.substring(3), 10);
        return stageEnum[String(a.Stage)] < stageEnum[String(b.Stage)]
          ? -1
          : stageEnum[String(a.Stage)] > stageEnum[String(b.Stage)]
          ? 1
          : textA < textB
          ? -1
          : textA > textB
          ? 1
          : numA < numB
          ? -1
          : numA > numB
          ? 1
          : 0;
      });
      tableList.push(versionList);
    }
    return tableList;
  }
  // Sort regions by state
  function sortByState(tablePVList) {
    var tableList = [];
    for (var versionList of tablePVList) {
      versionList.sort(function(a, b) {
        var stateA = stateEnum[a.State.toUpperCase()];
        var stateB = stateEnum[b.State.toUpperCase()];
        return stateA < stateB ? -1 : stateA > stateB ? 1 : 0;
      });
      tableList.push(versionList);
    }
    return tableList;
  }
  // Sort regions based on sort value and update tableList
  function sortRegionInfo(sortRegionByValue) {
    switch (sortRegionByValue) {
      case 'zone':
        var tablePVSorted = sortByZone(tablePVs.info);
        setTablePVs({ info: tablePVSorted });
        break;
      case 'state':
        var tablePVSorted = sortByState(tablePVs.info);
        setTablePVs({ info: tablePVSorted });
        break;
    }
  }
  // Invert sort
  function invertSort() {
    const reverse = searchPVs.info.reverse();
    updateTableList(reverse);
    setSearchPVs({ info: reverse });
  }
  // Invert regions
  function invertRegions() {
    const reverse = [];
    for (var pv of tablePVs.info) {
      reverse.push(pv.reverse());
    }
    setTablePVs({ info: reverse });
  }
  // Search
  function runSearch(event) {
    var searchList = [];
    for (const pv of pvInfo.info) {
      var pvString = JSON.stringify(pv).toLowerCase();
      if (pvString.includes(event.target.value.toLowerCase())) {
        searchList.push(pv);
      }
    }
    updateTableList(searchList);
    setSearchPVs({ info: searchList });
    setExpandAllCapabilitiesState({ info: false });
    setExpandedCapabilities({ info: [] });
  }
  // Parse container version
  function parseContainerVersion(containerVersion) {
    var cv = containerVersion;
    cv = cv.replace(': ', '-');
    cv = cv.replace('Version', '');
    return cv;
  }
  // Parse capabilities
  function parseCapabilities(capabilities) {
    var capString = '';
    var capList = capabilities.split(',');
    for (var entry of capList) {
      var splitName = entry.split('capability.');
      entry = splitName[splitName.length - 1]
        .replace("'", '')
        .replace(']', '')
        .replace('}', '')
        .replace('"', '')
        .replace('{', '')
        .replace('Name":"', '')
        .replace('e"', 'e');
      capString += entry + ',';
    }
    return capString;
  }
  // Handle capabilities expand click
  function handleCapabilitiesClick(pvKey) {
    if (expandedCapabilities.info.includes(pvKey)) {
      var remove = expandedCapabilities.info.indexOf(pvKey);
      expandedCapabilities.info.splice(remove, 1);
      if (expandedCapabilities.info.length == 0) {
        setExpandAllCapabilitiesState({ info: 'collapsed' });
      }
    } else {
      expandedCapabilities.info.push(pvKey);
      setExpandAllCapabilitiesState({ info: 'expanded' });
    }
    setExpandedCapabilities({ info: expandedCapabilities.info });
  }
  // expand display
  function expandDisplay() {
    return (
      <svg
        className="triangle-expand"
        focusable="false"
        viewBox="0 0 16 16"
        width="15"
      >
        <path d="M4 11h8L8 5l-4 6z" stroke="#d5dbdb" fill="#d5dbdb"></path>
      </svg>
    );
  }
  // collpase display
  function collapseDisplay() {
    return (
      <svg
        className="triangle-collapse"
        focusable="false"
        viewBox="0 0 16 16"
        width="15"
      >
        <path d="M4 11h8L8 5l-4 6z" stroke="#d5dbdb" fill="#d5dbdb"></path>
      </svg>
    );
  }
  // Get capabilities display
  function getCapabilitiesDisplay(pvKey, capabilities) {
    if (expandedCapabilities.info.includes(pvKey)) {
      var returnArray = capabilities.split(',');
      returnArray.pop();
      return [collapseDisplay(), returnArray];
    }
    return [expandDisplay(), []];
  }
  // Handle Expand All Capabilities Click
  function handleExpandAllCapabilitiesClick() {
    if (expandAllCapabilitiesState.info == 'collapsed') {
      setExpandAllCapabilitiesState({ info: 'expanded' });
      const allKeys = [];
      for (const pv of tablePVs.info) {
        for (const reg of pv) {
          allKeys.push(reg['Name'] + reg['InternalZone']);
        }
      }
      setExpandedCapabilities({ info: allKeys });
    } else {
      setExpandAllCapabilitiesState({ info: 'collapsed' });
      setExpandedCapabilities({ info: [] });
    }
  }
  // Get Expand All Capabilities Display
  function getExpandAllCapabilitiesDisplay() {
    if (expandAllCapabilitiesState.info == 'expanded') {
      return collapseDisplay();
    }
    return expandDisplay();
  }
  // Get Expand All Capabilities Display
  function getExpandAllCapabilitiesDisplay() {
    if (expandAllCapabilitiesState.info == 'expanded') {
      return collapseDisplay();
    }
    return expandDisplay();
  }
  // Handle version expand click
  function handleVersionClick(pvName) {
    if (expandedVersions.info.includes(pvName)) {
      var remove = expandedVersions.info.indexOf(pvName);
      expandedVersions.info.splice(remove, 1);
      if (expandedVersions.info.length == 0) {
        setExpandAllVersionsState({ info: 'collapsed' });
      }
    } else {
      expandedVersions.info.push(pvName);
      setExpandAllVersionsState({ info: 'expanded' });
    }
    setExpandedVersions({ info: expandedVersions.info });
  }
  // Handle Expand All Versions Click
  function handleExpandAllVersionsClick() {
    if (expandAllVersionsState.info == 'collapsed') {
      setExpandAllVersionsState({ info: 'expanded' });
      const allNames = [];
      for (const pv of tablePVs.info) {
        allNames.push(pv[0]['Name']);
      }
      setExpandedVersions({ info: allNames });
    } else {
      setExpandAllVersionsState({ info: 'collapsed' });
      setExpandedVersions({ info: [] });
    }
  }
  // Get Versions Arrow Display
  function getVersionDisplay(name) {
    if (expandedVersions.info.includes(name)) {
      return collapseDisplay();
    }
    return expandDisplay();
  }
  // Get Expand All Versions Display
  function getExpandAllVersionsDisplay() {
    if (expandAllVersionsState.info == 'expanded') {
      return collapseDisplay();
    }
    return expandDisplay();
  }
  // Click AMI ID
  function clickAmiId(hideBoolean, amiName) {
    setHideTable({ info: hideBoolean, ami_name: amiName });
  }
  // Refresh the s3 bucket
  function forceRefresh() {
    const refresh_payload = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: 'https://midway-auth.amazon.com',
    };
    fetch(window.location.origin + '/forceRefresh', refresh_payload);
    window.location.replace(window.location.href);
  }
  // Click Patching Dashboard
  function handleClickPatchingDashboard(hidePatchingDashboard) {
    setHidePatchingDashboard(!hidePatchingDashboard);
  }
  // Handle file upload
  function handleFileUpload(fileInfo) {
    setLoadedFileData({
      foundAmis: [],
      unknownAmis: [],
      loading: true,
    });
    reader.readAsArrayBuffer(fileInfo);
    reader.onload = function() {
      const filteredResult = filterFileData(reader.result);
      setLoadedFileData({
        foundAmis: filteredResult[0],
        unknownAmis: filteredResult[1],
        loading: false,
      });
    };
  }
  // Filter the data from the uploaded file
  function filterFileData(data) {
    const amiDict = countAmis(data);
    const foundAmis = findAMIs(amiDict);
    // Turn the dict of unknown AMIs into an array for the HTML display
    const unknownAmis = [];
    for (var unknownAmi in amiDict) {
      unknownAmis.push({
        AmiId: unknownAmi,
        Count: amiDict[unknownAmi]['Count'],
      });
    }
    return [foundAmis, unknownAmis];
  }
  // Count AMIs in file
  function countAmis(data) {
    const amiDict = {};
    var buffer = new Uint8Array(data);
    var lineFront = 0;
    var lineCount = 0;
    var amiIndex = 0;
    // Iterate through the file as Uint8
    for (var lineBack = 0; lineBack < buffer.length; lineBack++) {
      // Once a \n is found, it is the back of the current line (10 = \n)
      if (buffer[lineBack] === 10) {
        // Grab the line
        let line = new TextDecoder('utf-8').decode(
          buffer.slice(lineFront, lineBack),
        );
        // If it's the first line, we want to figure out which column AMI Ids are in
        if (lineCount == 0) {
          let headers = line.replaceAll('"', '').split(',');
          amiIndex = headers.length - headers.indexOf('AMI Id');
        } else {
          // If it's not the first line, count the AMI id from the line in the ami Dict
          let lineData = line.replaceAll('"', '').split(',');
          let amiString = lineData[lineData.length - amiIndex];
          if (amiDict[amiString] == undefined) {
            amiDict[amiString] = { Count: 1 };
          } else {
            amiDict[amiString]['Count'] += 1;
          }
        }
        // Update the front to be the start of the NEXT line
        lineFront = lineBack + 1;
        lineCount += 1;
      }
    }
    return amiDict;
  }
  // Find which AMIs are associated with known PVs and save the data
  function findAMIs(amiDict) {
    const foundAmis = [];
    // Associate the AMIs with their PV and populate foundAmis
    for (var pv of historicPvInfo.info) {
      var pvAmi = pv['EC2AmiId'];
      if (amiDict[pvAmi] != undefined) {
        foundAmis.push({
          Name: pv['Name'],
          State: pv['State'],
          Stage: pv['Stage'],
          Region: pv['InternalZone'].slice(0, 3),
          Count: amiDict[pvAmi]['Count'],
          AmiId: pvAmi,
        });
        // Remove the AMI Id from the amiDict after it is found so the final dict is only unknown AMIs
        delete amiDict[pvAmi];
      }
    }
    return sortInstances(foundAmis);
  }
  // Sort instances in name order
  function sortInstances(foundAmis) {
    foundAmis.sort(function(a, b) {
      var textA = a.Name.toUpperCase();
      var textB = b.Name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return foundAmis;
  }
  ////////////////////////////////////////////////////////////////////////////
  // HTML
  ////////////////////////////////////////////////////////////////////////////
  return getAppHTML(
    userInfo,
    syncTime,
    tablePVs,
    regionData,
    expandedVersions,
    expandAllVersionsState,
    hideTable,
    hidePatchingDashboard,
    loadedFileData,
    onChangeValuePV,
    onChangeValueRegion,
    invertSort,
    invertRegions,
    runSearch,
    getStatusClass,
    parseContainerVersion,
    parseCapabilities,
    handleCapabilitiesClick,
    getCapabilitiesDisplay,
    handleExpandAllCapabilitiesClick,
    getExpandAllCapabilitiesDisplay,
    handleVersionClick,
    handleExpandAllVersionsClick,
    getVersionDisplay,
    getExpandAllVersionsDisplay,
    clickAmiId,
    metadata,
    forceRefresh,
    handleClickPatchingDashboard,
    handleFileUpload,
  );
}

export default App;
